import axios from "axios";
import React, { createContext, useState } from "react";
import CALENDAR_CONFIG from "../config/calendar";
import counselingMenu from "../config/counselingMenu";

const initState = {
  name: "",
  phone: "",
  email: "",
  date: null,
  others: "",
  counselingMenu: "",
  status: {
    sended: false,
    isLoading: false,
    isError: false,
  },
};

export const FormContext = createContext(initState);

export const FormProvider = ({ children }) => {
  const [state, setState] = useState(initState);

  const handleText = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      date,
    }));
  };

  const handleRadio = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const createQuery = () => {
    const start = state.date.toISOString();
    const end = state.date.clone();
    end.add(CALENDAR_CONFIG.INTERVAL_RESERVE, "minutes");
    const endParam = end.toISOString();
    const tz = "Asia/Tokyo";
    const summary = `カウンセリング/${state.name}さん`;
    const targetMenu = counselingMenu.find(eachMenu => eachMenu.id === state.counselingMenu);
    const menu = targetMenu.label;
    const stringQuery = `summary=${summary}&name=${state.name}&start=${start}&end=${endParam}&attendees=${state.email}&tz=${tz}&phone=${state.phone}&others=${state.others}&menu=${menu}`;
    return stringQuery;
  };

  const createURL = (query) => {
    const { INSERT } = CALENDAR_CONFIG;
    const url = `${INSERT.DOMAIN}/${INSERT.PATH}?${query}`;
    return url;
  };

  const putCalendar = async (query) => {
    try {
      const url = createURL(query);
      await axios.get(url, query);
    } catch (e) {
      console.error(e);
    }
  };

  const createEmailURL = (query) => {
    const { EMAIL } = CALENDAR_CONFIG;
    const url = `${EMAIL.DOMAIN}/${EMAIL.PATH}?${query}`;
    return url;
  };
  const createSpreadURL = (query) => {
    const { SPREAD } = CALENDAR_CONFIG;
    const url = `${SPREAD.DOMAIN}/${SPREAD.PATH}?${query}`;
    return url;
  };

  const sendMail = async (query) => {
    try {
      const url = createEmailURL(query);
      await axios.get(url, query);
    } catch (e) {
      console.error(e);
    }
  };

  const writeSpread = async (query) => {
    try {
      const url = createSpreadURL(query);
      await axios.get(url, query);
    } catch (e) {
      console.error(e);
    }
  };

  const moveThanksPage = () => {
    const thanksPageHtml = "thanks.html";
    window.location.href = `${thanksPageHtml}`;
  };

  const handleError = () => {
    setState((prev) => ({
      ...prev,
      status: {
        ...prev.status,
        isError: !prev.status.isError,
      },
    }));
    window.alert("必要な項目が入力されていません");
  };

  const isEachEmpty = () => {
    const { name, phone, date } = state;
    if (!name) return true;
    if (!phone) return true;
    if (!date) return true;
    return false;
  };

  const insertEvent = async () => {
    try {
      if (isEachEmpty()) {
        handleError();
        return null;
      }
      setState((prev) => ({
        ...prev,
        status: {
          ...prev.status,
          isLoading: true,
        },
      }));
      const query = createQuery();
      await putCalendar(query);
      await sendMail(query);
      await writeSpread(query);
      moveThanksPage();
      setState((prev) => ({
        ...prev,
        status: {
          ...prev.status,
          sended: true,
          isLoading: false,
          isError: false,
        },
      }));
    } catch (e) {
      console.log("e");
      console.log(e);
      setState((prev) => ({
        ...prev,
        status: {
          ...prev.status,
          sended: true,
        },
      }));
    }
  };

  const provide = {
    ...state,
    handleText,
    handleRadio,
    handleDate,
    insertEvent,
    isEachEmpty,
  };

  return (
    <FormContext.Provider value={provide}>{children}</FormContext.Provider>
  );
};
