import moment from "moment-timezone";
import React, { useContext } from "react";
import CALENDAR_CONFIG, { timeMax } from "../../../config/calendar";
import MOMENT_CONFIG from "../../../config/momentConfig";
import { EventContext } from "../../../contexts/EventProvider";
import { FormContext } from "../../../contexts/FormProvider";
import { isBeforeStoreOpen } from "../../../util/CalendarUtil";
import css from "./dateTd.module.css";

const iconButtons = {
  able: (clickFunc, date) => (
    <button onClick={() => clickFunc(date)} className={css.td_button}>
      <p className={css.icon}>
        <p className={css.able_icon}>◯</p>
      </p>
    </button>
  ),
  ableClicked: () => (
    <button className={css.td_clicked_button} disabled>
      <p className={css.icon}>
        <p className={css.clicked_icon}>◯</p>
      </p>
    </button>
  ),
  unable: () => (
    <button className={css.button_disable} disabled>
      <p className={css.icon}>
        <p className={css.unable_icon}>×</p>
      </p>
    </button>
  ),
  tel: () => (
    <button className={css.button_disable} disabled>
      <p className={css.icon}>
        <p className={css.unable_icon}>TEL</p>
      </p>
    </button>
  ),
  care: (clickFunc, date) => (
    <button onClick={() => clickFunc(date)} className={css.td_button}>
      <p className={css.icon}>
        <p className={css.care_icon}>△</p>
      </p>
    </button>
  ),
  careClicked: (clickFunc, date) => (
    <button className={css.td_clicked_button} disabled>
      <p className={css.icon}>
        <p className={css.clicked_icon}>△</p>
      </p>
    </button>
  ),
};

const pickDay = (date) => {
  return date.format("DD");
};
const pickFullDate = (date) => {
  if (!date) return null;
  return date.format("YYYYMMDDTHH:mm");
};

const isBeforeNow = (eachDate) => {
  const nowTime = moment();
  const isBefore = eachDate.isSameOrBefore(nowTime);
  return isBefore;
};

const isAfterTimeMax = (eachDate) => {
  return eachDate.isAfter(timeMax, "day");
}

const isNextMorning = (eachDate) => {
  const current = moment();
  const currentHour = current.hour();
  const isCurrentMorning = 12 > currentHour;
  const tomorrow = moment().add(1, "days");
  const nextMorning = isCurrentMorning ? current : tomorrow;
  const nextDay = pickDay(nextMorning);
  const eachDateDay = pickDay(eachDate);
  const isSame = nextDay === eachDateDay;
  if (!isSame) return null;
  const nextMorningHour = nextMorning.hour(12);
  const isMorning = eachDate.isSameOrBefore(nextMorningHour);
  return isMorning;
};

const DateTD = (props) => {
  const { loopDate } = props;
  const eventContext = useContext(EventContext);
  const { events } = eventContext;
  const formContext = useContext(FormContext);
  const { handleDate, date: clickedDate } = formContext;
  const eachTd = [];

  const switchIconButtonElement = (i) => {
    const eachDate = loopDate.clone().add(i, MOMENT_CONFIG.TIME_PARAM.DAY);
    if (isBeforeStoreOpen(eachDate)) return iconButtons.unable();
    if (isBeforeNow(eachDate)) return iconButtons.unable();
    if (isAfterTimeMax(eachDate)) return iconButtons.unable();
    if (isNextMorning(eachDate)) return iconButtons.tel();
    let counter = 0;
    events.forEach((event) => {
      const { start, end } = event;
      const isAfterAtStart = eachDate.isSameOrAfter(start);
      const isBeforeAtEnd = eachDate.isBefore(end);
      if (isAfterAtStart && isBeforeAtEnd) {
        counter += 1;
      }
    });
    const eachDatePram = pickFullDate(eachDate);
    const clickedDateParam = pickFullDate(clickedDate);
    const isSame = eachDatePram === clickedDateParam;
    if (!counter && isSame) return iconButtons.ableClicked();
    if (!counter) return iconButtons.able(handleDate, eachDate);
    if (counter >= 2) return iconButtons.unable();
    if (isSame) return iconButtons.careClicked();
    return iconButtons.care(handleDate, eachDate);
  };

  for (let i = 0; i < CALENDAR_CONFIG.DISPLAY.DAY_NUM; i += 1) {
    const iconButton = switchIconButtonElement(i);
    const tdElement = <td className={css.common}>{iconButton}</td>;
    eachTd.push(tdElement);
  }

  return <>{eachTd}</>;
};

export default DateTD;
