import axios from "axios";
import moment from "moment-timezone";
import React, { createContext, useState } from "react";
import CALENDAR_CONFIG from "../config/calendar";

const thisYear = moment().year();
const nextYear = moment().add(1, "years").year();

const initState = {
  events: [],
  holidays: {
    [thisYear]: [],
    [nextYear]: [],
  },
  status: {
    isLoading: false,
  },
};

export const EventContext = createContext(initState);

export const EventProvider = ({ children }) => {
  const [state, setState] = useState(initState);

  const createQuery = () => {
    let query = "";
    CALENDAR_CONFIG.QUERY.forEach((eachQueryData, i) => {
      const { KEY: key, PARAM: param } = eachQueryData;
      const eachQueryParam = `${key}=${param}`;
      query += eachQueryParam;
      if (CALENDAR_CONFIG.QUERY.length - 1 > i) {
        query += "&";
      }
    });
    return query;
  };

  const createURL = (query) => {
    const { DOMAIN: domain, ID: id, PREFIX: prefix } = CALENDAR_CONFIG;
    const url = `${domain}/${id}/${prefix}?${query}`;
    return url;
  };

  const toFormatDate = (dateTime) => {
    const dateParam = moment(dateTime).clone();
    dateParam.seconds(0);
    return dateParam;
  };

  const getCalenderEvents = async () => {
    setState((prev) => ({
      ...prev,
      status: {
        ...prev.status,
        isLoading: true,
      },
    }));
    const query = createQuery();
    const url = createURL(query);
    const res = await axios.get(url);
    const events = res.data.items.map((item) => {
      const data = {
        start: toFormatDate(item.start.dateTime),
        end: toFormatDate(item.end.dateTime),
      };
      return data;
    });
    setState((prev) => ({
      ...prev,
      events: events,
      status: {
        ...prev.status,
        isLoading: false,
      },
    }));
  };

  const fetchHolidays = async (yearParam) => {
    const domain = "https://holidays-jp.github.io/api/v1";
    const expand = "date.json";
    const holidaysUrl = `${domain}/${yearParam}/${expand}`;
    const holidays = await axios.get(holidaysUrl);
    const holidayNumList = Object.keys(holidays.data);
    const formatted = holidayNumList.map((holiday) => {
      const date = moment(holiday);
      return {
        date: date,
        label: holidays.data[holiday],
      };
    });
    return formatted;
  };

  const getHolidays = async () => {
    const thisYearHoliday = await fetchHolidays(thisYear);
    const nextYearHoliday = await fetchHolidays(nextYear);
    setState((prev) => ({
      ...prev,
      holidays: {
        [thisYear]: thisYearHoliday,
        [nextYear]: nextYearHoliday,
      },
    }));
  };

  const provide = {
    ...state,
    getCalenderEvents,
    getHolidays,
  };
  return (
    <EventContext.Provider value={provide}>{children}</EventContext.Provider>
  );
};
