import Wrap from "./components/Wrap";
import ContextIndex from "./contexts/ContextIndex";

function App() {
  return (
    <ContextIndex>
      <div className="App">
        <Wrap />
      </div>
    </ContextIndex>
  );
}

export default App;
