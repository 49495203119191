import moment from "moment-timezone";
import CALENDAR_CONFIG from "../config/calendar";

const pickYearDate = (date) => {
  if (!date) return null;
  return date.format("YYYYMMDD");
};

export const isBeforeStoreOpen = (eachDate) => {
  const openDate = moment(CALENDAR_CONFIG.STORE_OPEN_DATE);
  const openDateParam = pickYearDate(openDate);
  const eachDateParam = pickYearDate(eachDate);
  const isBefore = eachDateParam < openDateParam;
  return isBefore;
};
