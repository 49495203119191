import React, { useContext } from "react";
import CALENDAR_CONFIG from "../../../config/calendar";
import MOMENT_CONFIG from "../../../config/momentConfig";
import { CalendarContext } from "../../../contexts/CalendarProvider";
import { EventContext } from "../../../contexts/EventProvider";
import css from "./dateTh.module.css";

const DateTr = () => {
  const calendarContext = useContext(CalendarContext);
  const { current } = calendarContext;
  const eventContext = useContext(EventContext);
  const { holidays } = eventContext;

  const formatToHolidaySearch = (date) => {
    return date.format('YYYYMMDD');
  }

  const TableHeader = () => {
    const emptyTh = <th></th>;
    const thList = [emptyTh];
    for (let i = 0; i < CALENDAR_CONFIG.DISPLAY.DAY_NUM; i += 1) {
      const displayDate = current.clone();
      displayDate.add(i, MOMENT_CONFIG.TIME_PARAM.DAY);
      const dayIndex = displayDate.day();
      const dayParam = MOMENT_CONFIG.DAY_PARAM.S[dayIndex];
      const jstHour = displayDate.clone();
      const dateIso = formatToHolidaySearch(jstHour);
      const dayClass = () => {
        if (!dayIndex) {
          return css.sunday;
        }
        if (dayIndex === 6) {
          return css.saturday;
        }
        const toIsHoliday = () => {
          const searchYear = Object.keys(holidays);
          const isHoliday = searchYear.some((yearParam) =>
            holidays[yearParam].some((holiday) => {
              const { date } = holiday;
              const holidayIso = formatToHolidaySearch(date);
              const isMatch = dateIso === holidayIso;
              return isMatch;
            })
          );
          return isHoliday;
        };
        if (toIsHoliday()) {
          return css.sunday;
        }
        return css.date;
      };

      const th = (
        <th className={css.common}>
          <div>{displayDate.date()}</div>
          <div className={dayClass()}>({dayParam})</div>
        </th>
      );
      thList.push(th);
    }
    return thList;
  };

  return (
    <tr>
      <TableHeader />
    </tr>
  );
};

export default DateTr;
