const counselingMenu = [
  {
    id: "selectShape",
    value: "selectShape",
    label: "colorオリジナルトレーニング体験（カウンセリング付）",
    comment: "",
  },
  {
    id: "allDiet",
    value: "allDiet",
    label: "BEAUTYトレーニング体験（カウンセリング付）【当店大人気！】",
    comment: "",
  },
  {
    id: "onlyCounseling",
    value: "onlyCounseling",
    label: "カウンセリングのみ",
    comment: "",
  },
];

export default counselingMenu;
