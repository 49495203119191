const MOMENT_CONFIG = {
  TZ: "Asia/Tokyo",
  TIME_PARAM: {
    MONTH: "months",
    DAY: "days",
  },
  DAY_PARAM: {
    S: ["日", "月", "火", "水", "木", "金", "土"],
    M: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  },
};

export default MOMENT_CONFIG;
