import React from "react";
import css from "./labelWithBoxUI.module.css";

const LabelWithBoxUI = (props) => {
  const {
    label,
    isRequire,
    placeholder,
    keyName,
    sampleParam,
    type = "text",
    changeFunc,
    isError,
    value,
  } = props;
  const isDisplayError = isRequire && isError && !value;

  return (
    <div class="ttl-form left">
      <label class="ttl">{label}</label>
      {isRequire && <span class="badge bg-danger">必須</span>}
      <input
        class={`form-control ${isDisplayError ? css.errorInput : null}`}
        onChange={changeFunc}
        type={type}
        name={keyName}
        placeholder={placeholder}
        required={isRequire}
      />
      {isDisplayError && (
        <p className={css.errorLabel}>{label}を入力してください</p>
      )}
      <p className={css.sample}>例）{sampleParam}</p>
    </div>
  );
};

export default LabelWithBoxUI;
