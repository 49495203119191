import moment from "moment-timezone";
import React, { createContext, useState } from "react";
import CALENDAR_CONFIG from "../config/calendar";
import MOMENT_CONFIG from "../config/momentConfig";
import { isBeforeStoreOpen } from "../util/CalendarUtil";

moment.locale("ja");

const currentDate = moment().tz(MOMENT_CONFIG.TZ);
const current = isBeforeStoreOpen(currentDate) ? moment(CALENDAR_CONFIG.STORE_OPEN_DATE).tz(MOMENT_CONFIG.TZ) :  currentDate;

const initState = {
  current: current,
};

export const CalendarContext = createContext(initState);

export const CalendarProvider = ({ children }) => {
  const [state, setState] = useState(initState);

  const changeWeek = (num) => {
    const nextDate = state.current.clone();
    nextDate.add(num, 'week');
    setState((prev) => ({
      ...prev,
      current: nextDate,
    }));
  };

  const provide = {
    ...state,
    changeWeek,
  };
  return (
    <CalendarContext.Provider value={provide}>
      {children}
    </CalendarContext.Provider>
  );
};
