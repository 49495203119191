import React, { useContext } from "react";
import CALENDAR_CONFIG from "../../../config/calendar";
import { CalendarContext } from "../../../contexts/CalendarProvider";
import DateTD from "./DateTD";
import css from "./dateBody.module.css";

const DateBody = () => {
  const calendarContext = useContext(CalendarContext);
  const { current } = calendarContext;
  const startTime = current
    .clone()
    .hour(CALENDAR_CONFIG.DISPLAY.START_HOUR)
    .minutes(0);
  const endTimeNum = current
    .clone()
    .hour(CALENDAR_CONFIG.DISPLAY.END_HOUR)
    .minutes(0);
  const eachCell = [];
  for (
    let loopDate = startTime;
    loopDate <= endTimeNum;
    loopDate.add(CALENDAR_CONFIG.INTERVAL_RESERVE, "minute")
  ) {
    const displayEachHour = loopDate.format("HH:mm");
    const propsLoopDate = loopDate.clone();
    const cell = (
      <tr>
        <th className={css.hour}>{displayEachHour}</th>
        <DateTD loopDate={propsLoopDate} />
      </tr>
    );
    eachCell.push(cell);
  }

  return <>{eachCell}</>;
};

export default DateBody;
