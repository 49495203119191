import React, { useContext } from "react";
import { FormContext } from "../../contexts/FormProvider";
import Calendar from "./Calendar";
import LabelWithBoxUI from "./LabelWithBoxUI";
import LabelWithRadioButtonUI from "./LabelWithRadioButtonUI";
import { Submitter } from "./Submitter";
import css from "./wrapForm.module.css";

const WrapForm = () => {
  const formContext = useContext(FormContext);
  const { handleText, status, name, phone, handleRadio } = formContext;
  return (
    <div className={css.form}>
      <LabelWithBoxUI
        label={"お名前"}
        isRequire
        placeholder={"お名前を入力してください"}
        keyName={"name"}
        sampleParam={"山田太郎"}
        changeFunc={handleText}
        value={name}
        isError={status.isError}
      />
      <br />
      <br />
      <LabelWithBoxUI
        label={"電話番号"}
        isRequire
        placeholder={"お電話番号を入力してください"}
        keyName={"phone"}
        sampleParam={"03-1234-5678"}
        changeFunc={handleText}
        value={phone}
        isError={status.isError}
      />
      <br />
      <br />
      <LabelWithBoxUI
        label={"メールアドレス(任意)"}
        isRequire={false}
        placeholder={"メールアドレスを半角で入力してください"}
        type={"email"}
        keyName={"email"}
        sampleParam={"example@gmail.com"}
        changeFunc={handleText}
      />
      <br />
      <br />

      <LabelWithRadioButtonUI
        label={"カウセリングメニュー"}
        isRequire={true}
        keyName={"counselingMenu"}
        changeFunc={handleRadio}
      />
      <br />
      <br />

      <div class="ttl-form left">
        <label class="ttl">ご希望ご予約日時</label>
        <span class="badge bg-danger">必須</span>
        <div className={css.module}>
          <Calendar />
        </div>
      </div>

      <br />
      <br />

      <br />
      <br />

      <div class="ttl-form left">
        <label class="ttl">その他ご質問（任意）</label>
        <textarea
          class="form-control"
          type="text"
          name="others"
          placeholder="何から始めて良いか分からない/まずは相談から始めたい/料金について質問したい"
          rows="5"
          onChange={handleText}
        ></textarea>
      </div>

      <br />
      <br />
      <Submitter />
      <div className={css.white}>※2秒ほどお時間を要します</div>
    </div>
  );
};

export default WrapForm;
