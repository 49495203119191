import moment from "moment-timezone";

export const timeMin = moment();
export const timeMax = moment().add(21, "days"); // inclusive: (timeMax)日後を含む

const timeMinString = timeMin.format("YYYY-MM-DD[T00:00:00Z]");
// Calendar APIのtimeMaxはexclusiveなので1日足す
const timeMaxString = timeMax.clone().add(1, "days").format("YYYY-MM-DD[T00:00:00Z]");

const CALENDAR_CONFIG = {
  DOMAIN: "https://www.googleapis.com/calendar/v3/calendars",
  ID: "ikebukuro@colorgym.tokyo",
  PREFIX: "events",
  QUERY: [
    {
      KEY: "key",
      PARAM: "AIzaSyBFUvqiH-ER0VF8KrstHK5OFac5fKlH82I",
    },
    {
      KEY: "timeMin",
      PARAM: timeMinString,
    },
    {
      KEY: "timeMax",
      PARAM: timeMaxString,
    },
    {
      KEY: "maxResults",
      PARAM: 2500,
    },
    {
      KEY: "orderBy",
      PARAM: "startTime",
    },
    {
      KEY: "singleEvents",
      PARAM: true,
    },
  ],
  DISPLAY: {
    START_HOUR: 11,
    END_HOUR: 20,
    DAY_NUM: 7,
  },
  INSERT: {
    DOMAIN: "https://sm15e26lo5.execute-api.ap-northeast-1.amazonaws.com",
    PATH: "default/colorgym-calendar-list",
  },
  EMAIL: {
    DOMAIN: "https://sm15e26lo5.execute-api.ap-northeast-1.amazonaws.com",
    PATH: "default/colorgym-email",
  },
  SPREAD: {
    DOMAIN: "https://sm15e26lo5.execute-api.ap-northeast-1.amazonaws.com",
    PATH: "default/colorgym-spleadsheet",
  },
  INTERVAL_RESERVE: 60,
  STORE_OPEN_DATE: "2022-01-08T00:00Z",
};

export default CALENDAR_CONFIG;
