import React from "react";
import counselingMenu from "../../config/counselingMenu";
import css from "./labelWithRadioButtonUI.module.css";

const LabelWithRadioButtonUI = (props) => {
  const { label, isRequire, keyName, changeFunc } = props;
  return (
    <div className={`ttl-form left ${css.wrapForm}`}>
      <div>
        <label className="ttl">{label}</label>
        {isRequire && (
          <span className={`badge bg-danger ${css.badge}`}>必須</span>
        )}
      </div>
      {counselingMenu.map((menu) => (
        <div className={css.wrapRadio}>
          <label className={css.ECM_RadioInput}>
            <input
              className={css.ECM_RadioInput_Input}
              type="radio"
              id={menu.id}
              name={keyName}
              value={menu.value}
              onChange={changeFunc}
            />
            <span className={css.ECM_RadioInput_DummyInput}></span>
            <span className={css.ECM_RadioInput_LabelText}>{menu.label}</span>
          </label>
          {menu.comment && <span className={css.comment}>{menu.comment}</span>}
        </div>
      ))}
    </div>
  );
};

export default LabelWithRadioButtonUI;
