import React from "react";
import css from "./calendar.module.css";
import Top from "./calendar/Top";
import Table from "./calendar/Table";

const Calendar = () => {
  return (
    <div className={css.container}>
      <div className={css.wrap}>
        <Top />
      </div>
      <Table />
    </div>
  );
};

export default Calendar;
