import React from "react";
import css from "./loading.module.css";

const LoadingUI = (props) => {
  const { isBlack } = props;
  return (
    <div className={isBlack ? css.blackLoader : css.loader}>Loading...</div>
  );
};

export default LoadingUI;
