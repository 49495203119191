import React, { useContext } from "react";
import { EventContext } from "../../../contexts/EventProvider";
import DateBody from "./DateBody";
import DateTr from "./DateTr";
import LoadingUI from "../../LoadingUI";
import css from "./table.module.css";

const Table = () => {
  const eventContext = useContext(EventContext);
  const { status } = eventContext;
  return (
    <div className={css.wrap}>
      <table className={css.table}>
        {status.isLoading ? (
          <LoadingUI isBlack />
        ) : (
          <>
            <DateTr />
            <DateBody />
          </>
        )}
      </table>
    </div>
  );
};

export default Table;
