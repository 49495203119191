import React, { useContext, useEffect } from "react";
import { EventContext } from "../contexts/EventProvider";
import WrapForm from "./form/WrapForm";
import Top from "./Top";

const Wrap = () => {
  const eventContext = useContext(EventContext);
  const { getCalenderEvents, getHolidays } = eventContext;

  useEffect(() => {
    getCalenderEvents();
    getHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div class="sec-contact" id="contact">
      <div class="base">
        <div class="inner">
          <div class="ttl-comp white center">
            <Top />
            <WrapForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wrap;
