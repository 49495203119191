import React, { useContext } from "react";
import { FormContext } from "../../contexts/FormProvider";
import LoadingUI from "../LoadingUI";

export const Submitter = () => {
  const formContext = useContext(FormContext);
  const { insertEvent, status } = formContext;

  const elements = {
    sended: (
      <button
        onClick={insertEvent}
        disabled
        class="btn btn-outline-light btn-lg form-btn"
      >
        お申し込みありがとうございます
      </button>
    ),
    ableToSend: (
      <button
        onClick={insertEvent}
        class="btn btn-outline-light btn-lg form-btn ttl"
      >
        無料カウンセリングに予約する　→
      </button>
    ),
    loading: <LoadingUI />,
  };

  if (status.isLoading) {
    return elements.loading;
  }
  if (status.sended) {
    return elements.sended;
  }

  return elements.ableToSend;
};
