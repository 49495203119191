import React from "react";

const Top = () => {
  return (
    <>
      <h2 class="ttl">
        無料カウンセリング
        <br class="sp" />
        予約フォーム
      </h2>
      <img
        src="./colorgym.tokyo_files/ttl_form.png"
        width="150"
        alt="counseling form"
        class="pc"
      />
      <p class="lead">
        あなたのお悩みやご希望をお聞かせください。
        <br />
        ぜひ無料カウンセリングへ!
      </p>{" "}
    </>
  );
};

export default Top;
