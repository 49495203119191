import React from "react";
import { CalendarProvider } from "./CalendarProvider";
import { EventProvider } from "./EventProvider";
import { FormProvider } from "./FormProvider";

const ContextIndex = (props) => {
  const { children } = props;

  return (
    <FormProvider>
      <CalendarProvider>
        <EventProvider> {children}</EventProvider>
      </CalendarProvider>
    </FormProvider>
  );
};

export default ContextIndex;
