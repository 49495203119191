import React, { useContext } from "react";
import { CalendarContext } from "../../../contexts/CalendarProvider";
import css from "./top.module.css";

const Top = () => {
  const calendarContext = useContext(CalendarContext);
  const { current, changeWeek } = calendarContext;
  const year = current.year();
  const month = current.month() + 1;
  return (
    <div className={css.top_wrapper}>
      <div className={css.top}>
        <button className={css.arrow_button} onClick={() => changeWeek(-1)}>
          ◀︎
        </button>
        <div className={css.year_month}>
          {year}/{month}
        </div>
        <button className={css.arrow_button} onClick={() => changeWeek(1)}>
          ▶︎
        </button>
      </div>
    </div>
  );
};

export default Top;
